/* --colors */
$color-base: #0e294b;
$color-bleu-nuit: #0e294b;
$color-bleu-nuit-nacre: #0e294b;
$color-bleu-vert: #335B74;
$color-blanc-papyrus: #cbcfca;
$color-vert-mousse: #345247;
$color-jaune-citron: #dfb342;
$color-noir-fonce: #3c3c3c;
$border-dark: rgba($color-base, 0.88);

$turquoise: $color-bleu-nuit;
$primary: $color-bleu-nuit;
$blue: $color-bleu-nuit-nacre;
$link: $color-bleu-nuit-nacre;
$white: color-blanc-papyrus;