.content-mechanical-and-hydraulic{
  padding: 3rem;
  margin-right: -10.5rem;
  z-index: 10;
}

.banner-mechanical-and-hydraulic {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./../../../assets/mecanique_et_hydraulique.JPG);
  background-position: 50%;
  background-size: cover;
  transform: scaleX(-1);
}