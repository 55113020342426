@import "./assets/app-colors.scss";

html {
  scroll-behavior: smooth;
}

.App,
.app {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $color-bleu-nuit-nacre;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    slideinleft: 1s ease-out;
  }

  to {
    transform: rotate(360deg);
    slideinleft: 1s ease-out;
  }
}

.is-from-top {}

/* --text */
/* --bleu-nuit-nacre */
.primary--bleu-nuit-nacre {
  color: $color-bleu-nuit-nacre;
}

/* --bleu-vert */
.primary--bleu-vert {
  color: $color-bleu-vert;
}

/* --blanc-papyrus */
.primary--blanc-papyrus {
  color: $color-blanc-papyrus;
}

/* --vert-mousse */
.primary--vert-mousse {
  color: $color-vert-mousse;
}

/* --jaune-citron */
.primary--jaune-citron {
  color: $color-jaune-citron;
}

/* --noir-fonce */
.primary--noir-fonce {
  color: #3c3c3c;
}

.banner-home {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./assets/banner-home.JPG);
  background-position: 50%;
  background-size: cover;
}

.hero.is-danger {
  background-color: #f14668;
  color: #fff;
}

.button.is-primary {
  background-color: $color-bleu-nuit-nacre;
}

.button.is-primary.is-outlined {
  background-color: $color-bleu-nuit-nacre;
  border-color: $color-jaune-citron;
  border-radius: 0 !important;
  border: $color-jaune-citron 2px solid;
  color: $color-jaune-citron;
  font-weight: bold;
  text-transform: uppercase;
}

.button.is-primary.is-outlined-hover {
  background-color: $color-bleu-nuit-nacre;
  border-color: none;
  border-radius: 0 !important;
  border: transparent 2px solid;;
  color: $color-jaune-citron;
  font-weight: bold;
  text-transform: uppercase;
}
.button.is-primary.is-outlined-hover:hover {
  border-color: $color-jaune-citron;
  border: $color-jaune-citron 2px solid;
}

.button.is-light.is-outlined {
  background-color: transparent;
  border: whitesmoke 2px solid;
  border-color: whitesmoke;
  color: whitesmoke;
  border-radius: 0 !important;
  font-weight: bold;
  text-transform: uppercase;
}

a.navbar-item,
.navbar-link {
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined.is-hovered,
.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined.is-focused {
  background-color: $color-jaune-citron;
  border-color: $color-jaune-citron;
  color: $color-bleu-nuit-nacre;
}

.hero.is-danger a.navbar-item:hover,
.hero.is-danger a.navbar-item.is-active,
.hero.is-danger .navbar-link:hover,
.hero.is-danger .navbar-link.is-active {
  background-color: #ef2e55;
  color: #fff;
}

.hero.is-primary a.navbar-item:hover,
.hero.is-primary a.navbar-item.is-active,
.hero.is-primary .navbar-link:hover,
.hero.is-primary .navbar-link.is-active {
  background-color: $color-bleu-nuit-nacre;
  // color: $color-bleu-nuit-nacre !important;
}

.navbar-item,
.navbar-link {
  color: $color-jaune-citron !important;
  padding: 0.5rem 1rem;
}

.has-text-primary {
  color: $color-jaune-citron !important;
}

.has-text-link {
  color: $color-bleu-nuit !important;
}

.has-background-primary {
  background-color: $color-bleu-nuit-nacre !important;
  color: white !important;
}

.has-background-info {
  background-color: $color-bleu-vert !important;
  color: $color-blanc-papyrus;
  .subtitle {
    color: whitesmoke !important;
  }
}

.has-text-primary-dark {
  color: $color-bleu-nuit-nacre !important;
}

.has-text-primary-light {
  color: $color-bleu-nuit-nacre !important;
}

.has-text-white {
  color: $color-blanc-papyrus !important;
}

.has-text-info {
  color: $color-bleu-vert !important;
}

.has-text-grey-light {
  color: $color-vert-mousse !important;
}

a.navbar-item:not(:first-child)::before {
  content: " ";
  margin-right: 1rem;
  border: solid 1px $color-jaune-citron;
  min-width: 2rem;
  width: 2rem;
  min-height: 2px;
  height: 2px;
  line-height: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.navbar-item:not(:first-child)::before {
  content: " ";
  border: solid 1px $color-jaune-citron;
  min-width: 1.5rem;
  width: 1.5rem;
  min-height: 2px;
  height: 2px;
  line-height: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: $color-bleu-nuit;
    color: $color-jaune-citron;
  }

  .hero.is-primary a.navbar-item:hover,
  .hero.is-primary a.navbar-item.is-active,
  .hero.is-primary .navbar-link:hover,
  .hero.is-primary .navbar-link.is-active {
    // background-color: $color-jaune-citron !important;
    color: $color-bleu-nuit !important;
  }

  .navbar-item:hover,
  .navbar-link:hover {
    // background-color: $color-jaune-citron;
    color: $color-bleu-nuit;
  }

  a.navbar-item:not(:first-child)::before {
    content: "";
    margin-right: 0;
    border: none;
    min-width: 0;
    width: 0;
    min-height: 0;
    height: 0;
    display: none;
  }

  div.navbar-item:not(:first-child)::before {
    content: "";
    margin-right: 0;
    border: none;
    min-width: 0;
    width: 0;
    min-height: 0;
    height: 0;
    display: none;
  }
}

.has-background-dark {
  background-color: $color-noir-fonce !important;
  color: $color-blanc-papyrus !important;
}

.navbar.sticky {
  margin-top: auto !important;
  margin-left: 0;
  margin-right: 0;
  min-height: 5.25rem;
}

@media screen and (min-width: 1024px) {
  .navbar.sticky {
    min-height: 5.25rem;
  }
}

.nav-brand-img.rotation,
img.ausyris-logo.rotation,
img.rotation {
  animation: App-logo-spin infinite 2s linear;
}

@media screen and (min-width: 769px) {
  .hero-body {
    padding: 3rem 3rem;
  }

  .hero.hero-discuss-with-us {
    margin-top: auto !important;
  }

  // .navbar-brand.sticky .navbar-item img {
  // max-height: 2.25rem;
  // }

  .navbar.sticky {
    min-height: 0 !important;
  }
}

@media screen and (max-width: 769px) {}

.button.is-light.is-outlined {
  background-color: transparent;
  border: $color-jaune-citron 2px solid;
  border-top-color: $color-jaune-citron;
  border-right-color: $color-jaune-citron;
  border-bottom-color: $color-jaune-citron;
  border-left-color: $color-jaune-citron;
  border-color: $color-jaune-citron;
  color: $color-jaune-citron;
  border-radius: 0 !important;
  font-weight: bold;
  text-transform: uppercase;
}

.button.is-light.is-outlined:hover {
  border-color: $color-bleu-nuit !important;
  color: $color-bleu-nuit !important;
}

.has-background-light {
  background-color: #EAECEA !important;
}
.has-background-very-light {
  background-color: whitesmoke !important;
  // background-color: #F6F6F6 !important;
  // background-color: #FCFCFC !important;
}



a.has-text-primary:hover,
a.has-text-primary:focus {
  color: $color-bleu-nuit-nacre !important;
}