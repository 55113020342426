.content-electronics-and-electrotechnics{
  padding: 3rem;
  margin-right: -10.5rem;
  z-index: 10;
}

// .hero-electronics-and-electrotechnics {}

.banner-electronics-and-electrotechnics {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./../../../assets/electronique_et_electrotechnique.JPG);
  background-position: 50%;
  background-size: cover;
  transform: scaleX(-1);
}