// .button-left-bar::before {
//   content: " ";
//   border: solid 1px #dfb342;
//   min-width: 2rem;
//   width: 2rem;
//   min-height: 2px;
//   height: 2px;
//   line-height: 1.5rem;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.ausyris-section {
  margin-top: -7.5rem;
  // margin-top: -17.5rem;
}
.content.ausyris-container {
  max-width: 756px;
  margin: 0 auto;
  position: relative;
  // width: auto;
  // margin-top: -7.5rem;
}
@media screen and (min-width: 1408px) {
  .content.ausyris-container :not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 960px;
    margin: 0 auto;
    width: auto;
  }
    .section.ausyris-section, .ausyris-section {
    margin-top: -7.5rem !important;
    // margin-top: -17.5rem;
  }
}
